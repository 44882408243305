<template>
  <section>
    <validation-observer ref="blogPostForm" #default="{invalid}">
      <b-form ref="form" @submit.prevent="onCreateOrUpdateBlogPost">
        <b-card
          class="blog-edit-wrapper"
        >
          <!-- media -->
          <b-media
            v-if="isUpdate"
            no-body
            vertical-align="center"
            class="mb-3"
          >
            <b-media-aside class="mr-75">
              <b-avatar
                size="38"
                :src="getValueFromSource(blogPostData, 'owner.avatar.path')"
              />
            </b-media-aside>
            <b-media-body>
              <h6 class="mb-25">
                {{ sentenceCase(`${getValueFromSource(blogPostData, 'owner.first_name')}`) }}
              </h6>
              <b-card-text>{{ formatDate(getValueFromSource(blogPostData, 'created'), 'MMM dd, yy') }}</b-card-text>
            </b-media-body>
          </b-media>
          <!--/ media -->

          <b-row>
            <b-col md="12">
              <b-form-group
                label="Title"
                label-for="blog-title"
                class="mb-2"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Title"
                  rules="required"
                >
                  <b-form-input
                    id="blog-title"
                    v-model="blogPostData.title"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            
            <b-col cols="12">
              <b-form-group
                label="Content"
                label-for="blog-content"
                class="mb-2"
              >
              <validation-provider
                #default="{ errors }"
                name="Content"
                rules="required"
              >
                <quill-editor
                  id="blog-content"
                  v-model="blogPostData.content"
                  :options="snowOption"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ !blogPostData.content && blogPostData.title? 'Please enter blog content' : '' }}</small>
              </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="3">
              <b-form-group
                label="Status"
                label-for="blog-edit-category"
                class="mb-2"
              >
                <v-select
                  id="blog-edit-category"
                  v-model="blogPostData.status"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="statusOption"
                  :reduce="val => val.value"
                  :clearable="false"
                />
              </b-form-group>
            </b-col>

            <b-col
              cols="12"
              class="mb-2"
            >
              <div class="border rounded p-2">
                <h4 class="mb-1">
                  Featured Image
                </h4>
                <b-media
                  no-body
                  vertical-align="center"
                  class="flex-column flex-md-row"
                >
                  <b-media-aside v-if="featuredImagePath">
                    <b-img
                      :src="featuredImagePath"
                      height="110"
                      width="170"
                      class="rounded mr-2 mb-1 mb-md-0"
                    />
                  </b-media-aside>
                  <b-media-body>
                    <small class="text-muted">Required image resolution 800x550, image size 5mb.</small>
                    <b-card-text class="my-50">
                    </b-card-text>
                    <div class="d-inline-block">
                      <validation-provider
                        #default="{ errors }"
                        name="Featured Image"
                        :rules="{
                          required: false,
                          size: 5000,
                          dimensions: { width: 800, height: 550 }
                        }"
                      >
                        <b-form-group
                          label=""
                          label-for="featured-image-file"
                        >
                          <b-form-file
                            id="featured-image-file"
                            ref="refInputEl"
                            v-model="blogFeaturedImage"
                            accept=".jpg, .png, .gif"
                            placeholder="Choose file"
                            :state="errors.length > 0 ? false : null"
                            @input="onImageRenderer"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </b-form-group>
                      </validation-provider>
                    </div>
                  </b-media-body>
                </b-media>
              </div>
            </b-col>

            <b-col
              cols="12"
              class="mt-50"
            >
              <b-button
                class="mb-5"
                style="width: 150px"
                variant="primary"
                block
                type="submit"
                :disabled="invalid || disableForm"
              >
                {{ isUpdate ? 'Update Post' : 'Create Post' }}
              </b-button>
            </b-col>
          </b-row>
        </b-card>
      </b-form>
    </validation-observer>
  </section>
</template>

<script>
import {
  BCard, 
  BMedia, 
  BAvatar, 
  BCardText, 
  BMediaAside, 
  BMediaBody, 
  BForm, 
  BRow, 
  BCol, 
  BFormGroup, 
  BFormInput, 
  BImg, 
  BFormFile, 
  BLink, 
  BButton,
} from 'bootstrap-vue'

import vSelect from 'vue-select'
import { quillEditor } from 'vue-quill-editor'
import Ripple from 'vue-ripple-directive'

import { required } from '@validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'

import { cloneDeep, get } from "lodash";

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

const rawBlogPostData = {
  title: "",
  content: "",
  status: "draft"
}
export default {
  components: {
    BCard,
    BMedia,
    BAvatar,
    BCardText,
    BMediaAside,
    BMediaBody,
    BForm,
    BLink,
    BImg,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BFormFile,
    vSelect,
    quillEditor,

    // validations
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  props: {
    updatingBlog: {
      type: [Object, null, undefined],
      default: null,
      required: false
    },
  },
  data() {
    return {
      loading: false,
      blogPostData: cloneDeep(rawBlogPostData),
      blogFeaturedImage: null,
      featuredImagePath: "",
      errorMessage: "",
      statusOption: [
        { label: 'Published', value: 'published' },
        { label: 'Draft', value: 'draft' }
      ],
      snowOption: {
        theme: 'snow',
      },

      required
    }
  },
  computed: {
    isUpdate(){
      return !!this.updatingBlog
    },
    disableForm(){
      return this.isUpdate ? !this.can(["blogs:CanUpdate"]) : !this.can(["blogs:CanAddNew"])
    }
  },
  watch: {
    updatingBlog: {
      handler(update){
        if (update){
          this.blogPostData = cloneDeep(update)
          const hasFeaturedImage = get(update, 'featured_image.path');
          if (hasFeaturedImage){
            this.featuredImagePath = hasFeaturedImage
          }
        }
      }, 
      deep: true, 
      immediate: true
    }
  },
  created() {},
  methods: {
    async onCreateOrUpdateBlogPost(){
      try {
        this.loading = true;
        
        const success = await this.$refs.blogPostForm.validate();
        if (!success){
          return;
        }

        const formData = new FormData();

        formData.append("title", this.blogPostData.title);
        formData.append("content", this.blogPostData.content);
        formData.append("status", this.blogPostData.status);

        if (this.blogFeaturedImage){
          formData.append('featured_image', this.blogFeaturedImage)
        }

        if (this.isUpdate){
          this.$emit("update", formData)
        } else {
          this.$emit("create", formData)
        }
      } catch (error){
        const error_message = get(error, "response.data.message") || error.message

        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error!',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: error_message
          },
        });
      } finally {
        this.loading = false
      }
    },
    onImageRenderer(file) {
     const reader = new FileReader()

      reader.addEventListener(
        'load',
        () => {
          this.featuredImagePath = reader.result
          this.blogFeaturedImage = file;
        },
        false,
      )

      if (file) {
        reader.readAsDataURL(file)
      }
    },
    resetForm(){
      Object.keys(cloneDeep(this.blogPostData)).forEach(key => {
        const resetValue = get(rawBlogPostData, key);
        this.blogPostData[key] = resetValue
        this.$set(this.blogPostData, resetValue);
      });
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/quill.scss';
@import '@core/scss/vue/pages/page-blog.scss';
</style>
