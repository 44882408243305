var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('validation-observer',{ref:"blogPostForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-form',{ref:"form",on:{"submit":function($event){$event.preventDefault();return _vm.onCreateOrUpdateBlogPost.apply(null, arguments)}}},[_c('b-card',{staticClass:"blog-edit-wrapper"},[(_vm.isUpdate)?_c('b-media',{staticClass:"mb-3",attrs:{"no-body":"","vertical-align":"center"}},[_c('b-media-aside',{staticClass:"mr-75"},[_c('b-avatar',{attrs:{"size":"38","src":_vm.getValueFromSource(_vm.blogPostData, 'owner.avatar.path')}})],1),_c('b-media-body',[_c('h6',{staticClass:"mb-25"},[_vm._v(" "+_vm._s(_vm.sentenceCase(("" + (_vm.getValueFromSource(_vm.blogPostData, 'owner.first_name')))))+" ")]),_c('b-card-text',[_vm._v(_vm._s(_vm.formatDate(_vm.getValueFromSource(_vm.blogPostData, 'created'), 'MMM dd, yy')))])],1)],1):_vm._e(),_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{staticClass:"mb-2",attrs:{"label":"Title","label-for":"blog-title"}},[_c('validation-provider',{attrs:{"name":"Title","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"blog-title","state":errors.length > 0 ? false : null},model:{value:(_vm.blogPostData.title),callback:function ($$v) {_vm.$set(_vm.blogPostData, "title", $$v)},expression:"blogPostData.title"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{staticClass:"mb-2",attrs:{"label":"Content","label-for":"blog-content"}},[_c('validation-provider',{attrs:{"name":"Content","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('quill-editor',{attrs:{"id":"blog-content","options":_vm.snowOption,"state":errors.length > 0 ? false : null},model:{value:(_vm.blogPostData.content),callback:function ($$v) {_vm.$set(_vm.blogPostData, "content", $$v)},expression:"blogPostData.content"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(!_vm.blogPostData.content && _vm.blogPostData.title? 'Please enter blog content' : ''))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"md":"3"}},[_c('b-form-group',{staticClass:"mb-2",attrs:{"label":"Status","label-for":"blog-edit-category"}},[_c('v-select',{attrs:{"id":"blog-edit-category","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.statusOption,"reduce":function (val) { return val.value; },"clearable":false},model:{value:(_vm.blogPostData.status),callback:function ($$v) {_vm.$set(_vm.blogPostData, "status", $$v)},expression:"blogPostData.status"}})],1)],1),_c('b-col',{staticClass:"mb-2",attrs:{"cols":"12"}},[_c('div',{staticClass:"border rounded p-2"},[_c('h4',{staticClass:"mb-1"},[_vm._v(" Featured Image ")]),_c('b-media',{staticClass:"flex-column flex-md-row",attrs:{"no-body":"","vertical-align":"center"}},[(_vm.featuredImagePath)?_c('b-media-aside',[_c('b-img',{staticClass:"rounded mr-2 mb-1 mb-md-0",attrs:{"src":_vm.featuredImagePath,"height":"110","width":"170"}})],1):_vm._e(),_c('b-media-body',[_c('small',{staticClass:"text-muted"},[_vm._v("Required image resolution 800x550, image size 5mb.")]),_c('b-card-text',{staticClass:"my-50"}),_c('div',{staticClass:"d-inline-block"},[_c('validation-provider',{attrs:{"name":"Featured Image","rules":{
                        required: false,
                        size: 5000,
                        dimensions: { width: 800, height: 550 }
                      }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"","label-for":"featured-image-file"}},[_c('b-form-file',{ref:"refInputEl",attrs:{"id":"featured-image-file","accept":".jpg, .png, .gif","placeholder":"Choose file","state":errors.length > 0 ? false : null},on:{"input":_vm.onImageRenderer},model:{value:(_vm.blogFeaturedImage),callback:function ($$v) {_vm.blogFeaturedImage=$$v},expression:"blogFeaturedImage"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1)],1)],1)],1)]),_c('b-col',{staticClass:"mt-50",attrs:{"cols":"12"}},[_c('b-button',{staticClass:"mb-5",staticStyle:{"width":"150px"},attrs:{"variant":"primary","block":"","type":"submit","disabled":invalid || _vm.disableForm}},[_vm._v(" "+_vm._s(_vm.isUpdate ? 'Update Post' : 'Create Post')+" ")])],1)],1)],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }