<template>
  <b-overlay :show="loading">
    <add-or-update-form ref="blogPostForm" @create="onCreateNewBlogPost" />
  </b-overlay>
</template>

<script>
import { BOverlay } from "bootstrap-vue"
import { get } from "lodash"

import AddOrUpdateForm from "@/@core/components/shared/admin/blogs/AddOrUpdateForm.vue"
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BOverlay,
    AddOrUpdateForm
  },
  directives: {},
  data() {
    return {
      loading: false
    }
  },
  methods: {
    async onCreateNewBlogPost(blogPost){
      try {
        this.loading = true;

        await this.useJwt().adminService.createNewBlogPost(blogPost);
        this.$refs.blogPostForm.resetForm();
    
        await this.$router.push({
          name: "admin-blogs"
        });

        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Succcess',
            icon: 'EditIcon',
            variant: 'success',
            text: `Blog Post created successfully`
          },
        });
      } catch (error){
        const error_message = get(error, "response.data.message") || error.message

        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error!',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: error_message
          },
        });
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/quill.scss';
@import '@core/scss/vue/pages/page-blog.scss';
</style>
